@if(!environment.holdingPage){
<div class="d-flex justify-content-center w-100">
  <h2 class="text-center">{{ year() }}</h2>
</div>
}
<div
  class="row g-0"
  [photoGalleryGroup]="{
    arrowEl: true,
    indexIndicatorSep: ' - ',

    allowPanToNext: true
  }"
>
  @if(!!images && images.length > 0){ @for(image of images; track image){
  @if(!!image.Key){
  <div class="col">
    <div
      class="awards-image-item-image text-center mt-4"
      [photoGallery]="getImageUrl(image.Key)"
    >
      <img [src]="getImageUrl(image.Key)" srcset @fadeIn />
    </div>
  </div>
  }} } @else {
  <h2 class="text-center">Photos coming soon</h2>
  }
</div>
