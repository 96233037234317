import { Component, input, effect, inject } from '@angular/core';
import { ListObjectsCommand, S3Client } from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { PhotoGalleryModule } from '@twogate/ngx-photo-gallery';
import { environment } from '../../../../environments/environment';
import { fadeIn } from '../../../shared/animations/animations';
import { Router } from '@angular/router';
const AWS_REGION = 'eu-west-2';
const IDENTITY_POOL_ID = 'eu-west-2:ff02b7f9-59c8-468c-ae7c-2d8cd9abddc1';
const BUCKET_NAME = 'developher-awards-photos';
@Component({
  selector: 'app-photo-gallery',
  imports: [PhotoGalleryModule],
  templateUrl: './photo-gallery.component.html',
  styleUrl: './photo-gallery.component.scss',
  animations: [fadeIn],
})
export class PhotoGalleryComponent {
  router = inject(Router);
  images: any;
  year = input<number>(2023);

  environment = environment;
  constructor() {
    const client = new S3Client({
      region: AWS_REGION,

      credentials: fromCognitoIdentityPool({
        clientConfig: { region: AWS_REGION },
        identityPoolId: IDENTITY_POOL_ID,
      }),
    });

    effect(() => {
      const command = new ListObjectsCommand({
        Bucket: BUCKET_NAME,
        Prefix: this.year().toString(),
        MaxKeys: environment.production ? 1000 : 10, //done to limit hits on AWS
      });
      client.send(command).then(({ Contents }) => {
        this.images = Contents?.filter((c) => !c.Key?.endsWith('/'));
      });
    });
  }

  getImageUrl(key: string) {
    return `https://${BUCKET_NAME}.s3.${AWS_REGION}.amazonaws.com/${key}`;
  }

  back() {
    this.router.navigate(['gallery']);
  }
}
